<template>
  <b-section class="features section-padding-0-100">
    <b-container>
      <section-header center="true">
        <template v-slot:sub-heading>{{ subHeading }} </template>
        <template v-slot:main-heading> {{ mainHeading }} </template>
      </section-header>

      <b-row class="align-items-center">
        <img src="@/assets/img/core-img/cfi.png" />
      </b-row>
    </b-container>
  </b-section>
</template>

<script>
export default {
  components: {},
  props: ["main-heading", "sub-heading"],
  computed: {},
  mounted() {},
};
</script>
