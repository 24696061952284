<template>
  <b-container>
    <b-row class="row align-items-center">
      <section-header left="true" class="gradient-text">
        <template v-slot:main-heading>OVERVIEW </template>
      </section-header>
      <b-col col="12" lg="12">
        <div class="creator-sec dd-bg">
          <div>
            <b-card no-body class="overflow-hidden" style="max-width: 540px">
              <b-row no-gutters>
                <b-col md="3">
                  <div class="detailed-img">
                    <img src="~@/assets/img/art-work/virus1.png" />
                  </div>
                </b-col>
                <b-col md="9">
                  <b-card-body>
                    <b-card-text>
                        <p>
                      SUPACell & Virus NFTs Presale Mint 20th Jan@2pm UTC on SUPA PORTAL
                      </p>
                         <p>
                        <a
                          class="app-link"
                          target="_blank"
                          href="https://portal.supa.foundation"
                          ><img src="~@/assets/img/core-img/portal.jpg"
                        /></a>
                      </p>
                      <p>
                        SUPA Foundation is an NFT based PVP-style Play-To-Earn
                        game set in an alternate futuristic expansive metaverse.
                      </p>
                     
                      <p>
                        Follow Supa Foundation's storyline on
                        Discord and install the App to collect SupaFuel Tokens
                        (later claimable in-game).
                      </p>
                  
                   
                    </b-card-text>
                  </b-card-body>
                </b-col>
              </b-row>
            </b-card>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  components: {},
  computed: {
    topSellers() {
      return this.$store.getters["topSellers"];
    },
  },
};
</script>

<style scoped>
.app-link img {
  max-width: 300px;
  height: auto;
}
.gradient-text {
  font-weight: bold;
  display: inline-block;
  background-image: linear-gradient(135deg, #f44336 0%, #3f51b5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.creator-sec {
  overflow: hidden;
  padding: 30px 20px 10px;
}
.dd-bg {
  background: #090060;
  border-radius: 20px;
}
.detailed-img {
  padding: 5px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 10px;
  border: 2px dashed rgba(255, 255, 255, 0.2);
}
</style>
