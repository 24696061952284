
<template>
  <b-section class="about-us-area section-padding-100 clearfix">
    <b-card-seller> </b-card-seller>
  </b-section>
</template>





<script>
import BCardSeller from "../../../local/UI/Home/BCardSeller.vue";
export default {
  components: {
    BCardSeller,
  },
};
</script>