<template>
  <div class="container">
    <section-header center="true">
      <template v-slot:main-heading> {{ mainHeading }}</template>
      <template v-slot:sub-heading> {{ subHeading }}</template>
    </section-header>
    <b-row>
      <b-col col="12" lg="12">
        <div class="creator-sec dd-bg">
          <div>
            <b-card no-body class="overflow-hidden" style="max-width: 540px">
              <b-row no-gutters>
                <div class="banner">
                  <img src="~@/assets/img/art-work/virusbanner.png" />
                </div>
                <b-col md="12">
                  <b-card-body>
                    <b-card-text>
                      <p></p>
                      <p>
                        Only 10,000 will be created! NFT owners can generate
                        further NFTs via "Fusion" and resell them on the
                        Secondary Marketplace.
                      </p>
                      <p>SUPACells are currently in development.</p>
                      <p>
                        Our
                        <a
                          href="https://supa-foundation.gitbook.io/wiki-1/"
                          target="_blank"
                          >Whitepaper</a
                        >
                        contains further info regarding Gameplay.
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-col>
              </b-row>
            </b-card>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["main-heading", "sub-heading"],
  computed: {
    popularCollections() {
      return this.$store.getters["popularCollections"];
    },
  },
};
</script>
<style scoped>
.dd-bg {
  background: #090060;
  border-radius: 20px;
  padding: 20px;
}
.banner {
  padding: 10px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 10px;
  border: 2px dashed rgba(255, 255, 255, 0.2);
}
p {
  padding-left: 20px;
}
</style>
