import { createRouter, createWebHistory } from "vue-router";
import Home from "../pages/Home.vue";
// import LogIn from "../pages/Auth/Login.vue";
// import SignUp from "../pages/Auth/Signup.vue";
// import Profile from "../pages/Profile.vue";
// import Authors from "../pages/Authors.vue";
import FAQ from "../pages/FAQ.vue";
import Roadmap from "../pages/Roadmap.vue";
// import Auctions from "../pages/Auctions.vue";
// import CreateItem from "../pages/CreateItem.vue";
// import ItemDetails from "../pages/ItemDetails.vue";
// import Discover from "../pages/Discover.vue";
// import WalletConnect from "../pages/WalletConnect.vue";
import NotFound from "../pages/NotFound.vue";

const router = createRouter({
  history: createWebHistory(),
  mode: "history",
  scrollBehavior(_, _2, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    { path: "/", redirect: "/home" },
    { path: "/home", component: Home },

    // {
    //   path: "/signup",
    //   component: SignUp,
    // },
    {
      path: "/whitepaper",
      beforeEnter() {
        location.href = "https://supa.foundation/SUPAWhitepaper.pdf";
      },
    },
    {
      path: "/privacypolicy",
      beforeEnter() {
        location.href = "https://supa.foundation/privacypolicy.pdf";
      },
    },
    {
      path: "/termsofuse",
      beforeEnter() {
        location.href = "https://supa.foundation/termsofuse.pdf";
      },
    },
    {
      path: "/litepaper",
      beforeEnter() {
        location.href = "https://supa.foundation/SUPALitepaper.pdf";
      },
    },
    // { path: "/login", component: LogIn },
    // { path: "/profile", component: Profile },
    // { path: "/authors", component: Authors },
    { path: "/faq", component: FAQ },
    { path: "/roadmap", component: Roadmap },

    // { path: "/Auctions", component: Auctions },
    // { path: "/createItem", component: CreateItem },
    // { path: "/itemDetails", component: ItemDetails },
    // { path: "/discover", component: Discover },
    // { path: "/walletConnect", component: WalletConnect },

    { path: "/:notfound(.*)", component: NotFound, meta: { NotFound: true } },
  ],
});
router.beforeEach(function (_, _2, next) {
  setTimeout(() => {
    window.scrollTo(0, 0);
    next();
  }, 10);
});
export default router;
