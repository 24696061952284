<template>
  <div class="timelineHeader">
    <h3>Milestones</h3>
  </div>
</template>

<style scoped>
.timelineHeader {
  padding: 32px;
  padding: 32px;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.03);
  position: relative;
  z-index: 3;
}

.timelineHeader h3 {
  font-size: 32px;
  font-size: 32px;
  font-size: 2rem;
  margin: 0;
}

.timelineHeader h3 + span {
  font-size: 19.2px;
  font-size: 19.2px;
  font-size: 1.2rem;
  color: #fff;
}
</style>
