<template>
  <b-section class="hero-section moving section-padding" id="home">
    <div class="moving-bg"></div>
    <!-- Hero Content -->
    <div class="hero-section-content">
      <b-container>
        <div class="row align-items-center">
          <!-- Welcome Content -->
          <b-col col="12" lg="6" md="12">
            <div class="welcome-content">
              <h1>
                Welcome Recruit, <br />To The
                <span class="gradient-text">SUPA Foundation</span>
              </h1>
              <p class="w-text">
                In a distant future, Earth is plagued by mutated versions of the
                Coronavirus. It is up to The Foundation to combat the virus.
              </p>
              <p>
                Join us to save humanity in this upcoming NFT-based Play-To-Earn
                Game!
              </p>
              <div class="dream-btn-group">
                <base-button @click="goto(1)" mode="btn more-btn me-3"
                  >Whitepaper</base-button
                >
                <base-button @click="goto(2)" mode="btn more-btn"
                  >Discord</base-button
                >
              </div>
            </div>
          </b-col>
          <b-col lg="6"></b-col>
        </div>
      </b-container>
    </div>
  </b-section>
</template>
<script>
export default {
  methods: {
    goto(val) {
      if (val == 1) {
        window.location.href = "https://supa.foundation/SUPAWhitepaper.pdf";
      } else if (val == 2) {
        window.location.href = "https://discord.com/invite/7zP4fs3U3N";
      } else {
        window.location.href = "#";
      }
    },
  },
  data() {},
};
</script>

<style scoped>
.hero-section {
  position: relative;
  min-height: 700px;
  background: transparent no-repeat bottom center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-section.moving {
  overflow: hidden;

  background: url("~@/assets/img/bg-img/header-1.jpg") no-repeat center top;
  background-size: cover;
}
@media (max-width: 991px) {
  .hero-section.moving {
    background-position: left top;
  }
}

.moving-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 95%;
  width: 100%;

  opacity: 1;
  visibility: inherit;
  background: transparent url("~@/assets/img/core-img/rain1.png") repeat scroll
    0 100%;
}

.welcome-content {
  position: relative;
  z-index: 1;
  margin-top: 90px;
  border-radius: 15px;
  background: #090060;
  padding: 20px;
}

.promo-section {
  margin-bottom: 30px;
}

.special-head {
  color: #28cc8b;
  padding-left: 40px;
  font-size: 18px;
  position: relative;
}
.special-head:before {
  content: "";
  background: #3964f9;
  position: absolute;
  top: 50%;
  left: 0;
  width: 30px;
  height: 2px;
}
.gradient-text {
  font-weight: bold;
  display: inline-block;
  background-image: linear-gradient(135deg, #f44336 0%, #3f51b5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.gradient-text.blue {
  background-image: linear-gradient(35deg, #17ead9 0%, #6078ea 100%);
}
</style>
