<template>
  <div v-if="isLoading">
    <base-spinner> </base-spinner>
  </div>
  <base-header>
    <template v-slot:heading>Frequently Asked Questions</template>
  </base-header>
  <b-section class="blog-area section-padding-100">
    <b-container>
      <b-row>
        <div class="container">
          <div class="accordion">
            <div class="accordion-item" id="question1">
              <a class="accordion-link" href="#question1">
                <div class="flex">
                  <h3>
                    Only 10,000 NFTS? What about the rest of us who want to play
                    the game?
                  </h3>
                </div>
                <i class="icon ion-md-arrow-forward"></i>
                <i class="icon ion-md-arrow-down"></i>
              </a>
              <div class="answer">
                <p>
                  There will be only 10,000 NFTs created. NFT Holders can then
                  create new NFT viruses or SUPACells via "Fusion" and can be
                  resold on the Secondary Marketplace for new users to buy.
                </p>
              </div>
              <hr />
            </div>
            <div class="accordion-item" id="question2">
              <a class="accordion-link" href="#question2">
                <div class="flex">
                  <h3>What will the gameplay be like?</h3>
                </div>
                <i class="icon ion-md-arrow-forward"></i>
                <i class="icon ion-md-arrow-down"></i>
              </a>
              <div class="answer">
                <p>
                  The game will be turn-based PvP style and will be
                  Play-To-Earn. We plan to make the game competitive for all
                  players and will not be geared towards Pay-To-Win. More
                  details in the whitepaper.
                </p>
              </div>
              <hr />
            </div>
            <div class="accordion-item" id="question3">
              <a class="accordion-link" href="#question3">
                <div class="flex">
                  <h3>
                    What is the purpose of the Supa Foundation SupaFuel App?
                  </h3>
                </div>
                <i class="icon ion-md-arrow-forward"></i>
                <i class="icon ion-md-arrow-down"></i>
              </a>
              <div class="answer">
                <p>
                  This is our early adopter, loyalty-based App. It enables users
                  to collect SupaFuel Tokens that will later be claimable
                  in-game. Users will also be able to follow the latest
                  developments and updates from us.
                </p>
              </div>
              <hr />
            </div>
            <div class="accordion-item" id="question4">
              <a class="accordion-link" href="#question4">
                <div>
                  <h3>How will NFT resale tax be used?</h3>
                </div>
                <i class="icon ion-md-arrow-forward"></i>
                <i class="icon ion-md-arrow-down"></i>
              </a>
              <div class="answer">
                <p>
                  There will be a 10% on resales on the Secondary Marketplace.
                  Its distribution can be found in the whiteppaper.
                </p>
              </div>
              <hr />
            </div>
            <div class="accordion-item" id="question5">
              <a class="accordion-link" href="#question5">
                <div>
                  <h3>When will the NFTs be relased?</h3>
                </div>
                <i class="icon ion-md-arrow-forward"></i>
                <i class="icon ion-md-arrow-down"></i>
              </a>
              <div class="answer">
                <p>
                  We will be launching our $SUPA token first that will be used
                  for the whole ecosystem. The NFTs will be released soon after.
                  See Roadmap for more info.
                </p>
              </div>
              <hr />
            </div>
            <div class="accordion-item" id="question6">
              <a class="accordion-link" href="#question6">
                <div>
                  <h3>Which blockchain will be used?</h3>
                </div>
                <i class="icon ion-md-arrow-forward"></i>
                <i class="icon ion-md-arrow-down"></i>
              </a>
              <div class="answer">
                <p>
                  We will be using the Fantom Opera Blockchain due to its
                  amazing community and subsecond confirmation times ideal for
                  gaming.
                </p>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </b-row>
    </b-container>
  </b-section>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      isLoading: true,
    };
  },

  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  },
};
</script>
<style scoped>
*,
*::before,
*::after {
  margin: 0;
  padding: 0px 0px 0px 10px;
  box-sizing: inherit;
}

html {
  font-family: "DM Sans", sans-serif;
  font-size: 10px;
  box-sizing: border-box;
}

/*Section used for centering process*/
section {
  width: 100%;
  padding: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 100%;
  max-width: 80rem;
  margin: 0 auto 0 -1.5%;
  padding: 0;
}

.accordion-item {
  background-color: #090060;
  border-radius: 0.4rem;
}

.accordion-item hr {
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.accordion-link {
  font-size: 15px;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  background-color: #090060;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
}

.accordion-link h3 {
  font-weight: 500;
  font-size: 18px;
}

.accordion-link i {
  color: #e7d5ff;
  padding: 0.5rem;
}

.accordion-link ul {
  display: flex;
  align-items: flex-end;
  list-style-type: none;
  margin-left: 25px;
}

.accordion-link li {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.4);
  padding: 0 0 1px 5px;
}

.accordion-link div {
  display: flex;
}

.accordion-link .ion-md-arrow-down {
  display: none;
}

.answer {
  max-height: 0;
  overflow: hidden;
  position: relative;
  background-color: #090060;
  transition: max-height 650ms;
}

.answer p {
  color: #fff;
  font-size: 16px;
  padding: 1rem;
}

.accordion-item:target .answer {
  max-height: 20rem;
}

.accordion-item:target .accordion-link .ion-md-arrow-forward {
  display: none;
}

.accordion-item:target .accordion-link .ion-md-arrow-down {
  display: block;
}
</style>
